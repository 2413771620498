<template>
  <div class="dashboard">
    <FileEditor></FileEditor>
  </div>
</template>

<script>
import FileEditor from '@/components/file-editor/fileeditor'
export default {
    name: 'FileEditorPage',
    components: {
        FileEditor
    },
    methods: {

    },
}
</script>