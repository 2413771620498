<template>
    <div class="file-video">
        <va-icon class="fa fa-close" color="danger" size="large" @click="removeFileFromEdit()"></va-icon>
        <video :id="'test' + index" class="video-js vjs-fluid"></video>
        <div class="row">
            <div class="flex md5">
                <va-button-group :rounded="false" :flat="true">
                    <va-button icon="fa-angle-double-left" @click="changeVideoTime(-60)" />
                    <va-button icon="fa-angle-left" @click="changeVideoTime(-15)" />
                    <va-button icon="fa-angle-right" @click="changeVideoTime(15)" />
                    <va-button icon="fa-angle-double-right" @click="changeVideoTime(60)" />
                </va-button-group>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex"
import videojs from 'video.js';

export default {
    name: 'FileVideo',
    data: () => ({
        player: null
    }),
    props: {
        media: Object,
        index: Number
    },
    methods: {
        ...mapActions("files", { removeFromEdit: "removeFromEdit", addPlayerToEditFile: "addPlayerToEditFile" }),
        loadSource() {
            this.player = videojs('test' + this.index, {
                controls: true,
                sources: [
                    {
                        type: "video/mp4",
                        src: this.media.source
                    }
                ]
            })
            this.addPlayerToEditFile({
                name: this.media.name,
                player: this.player
            })
        },
        changeVideoTime(time) {
            this.player.currentTime(this.player.currentTime() + time);
        },
        removeFileFromEdit() {
            this.removeFromEdit(this.media)
            this.player.dispose()
        }
    },
    computed: {

    },
    mounted() {
        this.loadSource()
    },
    beforeUnmount() {
        if (this.player) {
            this.player.dispose()
        }
    },
    watch: {

    }
}
</script>
<style scoped>
.file-video {
    position: relative;
    margin: 20px 0;
}
.fa-close {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    padding: 10px;
    background: white;
}
</style>