<template>
    <div class="dashboard">
        <h1>{{ $t('pages.editor.text.file-editor') }}</h1>

        <div class="row">
            <div class="flex md5">
                <va-card v-if="selectedEditFiles.length > 0">
                    <va-card-content>
                        <h2>{{ $t('pages.editor.text.selected-file') }}</h2>
                        <div v-for="(editFile, index) in selectedEditFiles" :key="editFile._id">
                            <FileVideo
                                :media="editFile"
                                :index="index"
                                :class="{ selected: selectedEditFiles.length > 1 && selectedEditVideo.name === editFile.name }"
                            ></FileVideo>
                        </div>
                    </va-card-content>
                </va-card>
                <va-card v-else>
                    <va-card-content>
                        <h2>{{ $t('pages.editor.text.no-files') }}</h2>
                        <va-button
                            @click="showEditorModal = !showEditorModal"
                            class="my-3 px-3"
                            icon="add"
                        >{{ $t('pages.editor.buttons.add-file') }}</va-button>
                    </va-card-content>
                </va-card>
            </div>
            <div class="flex md5" v-if="selectedEditFiles.length > 0">
                <va-card>
                    <va-card>
                        <va-card-title>{{ $t('pages.editor.text.selected-files') }}</va-card-title>
                        <va-card-content>
                            <va-button
                                @click="showEditorModal = !showEditorModal"
                                class="my-3 px-3"
                                icon="add"
                            >{{ $t('pages.editor.buttons.add-file') }}</va-button>
                        </va-card-content>
                    </va-card>
                </va-card>
                <va-card>
                    <va-card>
                        <va-card-title>{{ $t('pages.editor.text.trim') }}</va-card-title>
                        <va-card-content>
                            <va-select
                                class="mb-4"
                                :label="$t('pages.editor.text.select-trim')"
                                :options="selectedEditFiles"
                                v-model="tempSelectedEditVideo"
                                rounded="false"
                                text-by="name"
                                track-by="name"
                                @update:model-value="changeValue"
                                v-if="selectedEditFiles.length > 1"
                            />

                            <va-select
                                v-else
                                class="mb-4 disabled"
                                :label="$t('pages.editor.text.select-trim')"
                                :options="selectedEditFiles"
                                v-model="tempSelectedEditVideo"
                                rounded="false"
                                text-by="name"
                                :disabled="true"
                            />
                            <div class="row trim-inputs">
                                <div class="flex md6">
                                    <input
                                        type="time"
                                        v-model="markIn"
                                        :class="{ 'error': markInRequired }"
                                    />
                                    <va-button @click="getStartTime()">{{ $t('pages.editor.buttons.mark-in') }}</va-button>
                                </div>
                                <div class="flex md6">
                                    <input
                                        type="time"
                                        v-model="markOut"
                                        :class="{ 'error': markOutRequired }"
                                    />
                                    <va-button @click="getStopTime()">{{ $t('pages.editor.buttons.mark-out') }}</va-button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="flex md12">
                                    <va-input
                                        v-model.lazy="email"
                                        :placeholder="$t('pages.editor.text.email-notification')"
                                        outline
                                        :label="$t('pages.editor.text.email')"
                                    ></va-input>
                                </div>
                                <div class="flex md12">
                                    <va-input
                                        v-model.lazy="newName"
                                        :placeholder="$t('pages.editor.text.new-name')"
                                        outline
                                        :label="$t('pages.editor.text.new-name')"
                                        :class="{ 'error': nameRequired }"
                                    ></va-input>
                                </div>
                            </div>
                            <div class="row">
                                <div class="flex md2">
                                    <va-button @click="sendTrimRequest()" :loading="isTrimming">{{ $t('pages.editor.text.trim') }}</va-button>
                                </div>
                            </div>
                        </va-card-content>
                    </va-card>
                </va-card>
                <va-card v-if="selectedEditFiles.length > 1">
                    <va-card>
                        <va-card-title>{{ $t('pages.editor.text.concat') }}</va-card-title>
                        <va-card-content>
                            <div class="row">
                                <div class="flex md12">
                                    <va-input
                                        v-model.lazy="concatName"
                                        :placeholder="$t('pages.editor.text.new-name')"
                                        outline
                                        :label="$t('pages.editor.text.new-name')"
                                        :class="{ 'error': concatNameRequired }"
                                    >
                                        <template #append>
                                            <va-button
                                                @click="sendConcatRequest()"
                                                :loading="isConcatting"
                                            >{{ $t('pages.editor.text.concat') }}</va-button>
                                        </template>
                                    </va-input>
                                </div>
                                <div class="flex md12">
                                    <va-input
                                        v-model.lazy="email"
                                        :placeholder="$t('pages.editor.text.email-notification')"
                                        outline
                                        label="Email"
                                    ></va-input>
                                </div>
                            </div>
                        </va-card-content>
                    </va-card>
                </va-card>
                <va-card>
                    <va-card>
                        <va-card-title>{{ $t('pages.editor.text.preview-result') }}</va-card-title>
                        <va-card-content>
                            <video id="preview" class="video-js vjs-fluid" v-if="previewUrl"></video>
                        </va-card-content>
                    </va-card>
                </va-card>
            </div>
        </div>
        <va-modal
            v-model="showEditorModal"
            :title="$t('pages.explorer.text.file-explorer')"
            size="large"
            no-outside-dismiss
            hide-default-actions
        >
            <slot>
                <FileExplorer :showFileActions="false"></FileExplorer>
            </slot>
            <template #footer>
                <va-button class="mr-4" @click="addNewFiles()">Ok</va-button>
                <va-button class="mr-4" @click="showEditorModal = !showEditorModal">{{ $t('pages.explorer.buttons.cancel') }}</va-button>
            </template>
        </va-modal>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex"
import videojs from 'video.js';
import FileExplorer from '@/components/file-explorer/fileexplorer'
import FileVideo from '@/components/file-editor/video'
import moment from 'moment'

const prettyBytes = require('pretty-bytes');

export default {
    name: 'fileexplorer',
    data: () => ({
        showEditorModal: false,
        player: null,
        source: null,
        markIn: null,
        markOut: null,
        newName: null,
        concatName: '',
        previewPlayer: null,
        nameRequired: false,
        markInRequired: false,
        markOutRequired: false,
        concatNameRequired: false,
        tempSelectedEditVideo: null,
        email: ''
    }),
    components: {
        FileExplorer,
        FileVideo
    },
    methods: {
        ...mapActions("files", { setSelectedFile: "setSelectedFile", trimFileRequest: "trimFileRequest", concatFileRequest: "concatFileRequest", setSelectedEditVideo: "setSelectedEditVideo" }),
        getStartTime() {
            this.markIn = moment.utc(this.selectedEditVideo.player.currentTime() * 1000).format('HH:mm:ss')
        },
        getStopTime() {
            this.markOut = moment.utc(this.selectedEditVideo.player.currentTime() * 1000).format('HH:mm:ss')
        },
        sendTrimRequest() {
            this.nameRequired = false
            this.markInRequired = false
            this.markOutRequired = false
            if (this.newName && this.newName !== '' && this.markIn && this.markOut) {
                this.trimFileRequest({
                    path: this.selectedEditVideo.fullPath,
                    markIn: moment(this.markIn, 'HH:mm:ss').diff(moment().startOf('day'), 'seconds'),
                    markOut: moment(this.markOut, 'HH:mm:ss').diff(moment().startOf('day'), 'seconds'),
                    output: this.newName + ".mp4",
                    email: this.email
                })
            } else {
                if (!this.newName) this.nameRequired = true;
                if (!this.markIn) this.markInRequired = true;
                if (!this.markOut) this.markOutRequired = true
            }
        },
        sendConcatRequest() {
            if (this.concatName && this.concatName !== '') {
                this.concatFileRequest({
                    media: [{
                        path: '/mnt/' + this.selectedEditFiles[0].fullPath,
                        order: 0
                    }, {
                        path: '/mnt/' + this.selectedEditFiles[1].fullPath,
                        order: 1
                    }],
                    email: this.email,
                    output: this.concatName + ".mp4"
                })
            } else {
                this.concatNameRequired = true
            }


        },
        addNewFiles() {
            this.setSelectedFile(this.selectedItem)
            this.showEditorModal = !this.showEditorModal
        },
        changeValue(newVideo) {
            this.setSelectedEditVideo(newVideo)
        }
    },
    computed: {
        ...mapState("mounts", {
            selectedItem: "selectedItem"
        }),
        ...mapState("files", {
            selectedEditFiles: "selectedEditFiles", actionState: "actionState", previewUrl: "previewUrl", selectedEditVideo: "selectedEditVideo", isTrimming: "isTrimming", isConcatting: "isConcatting"
        }),
        ...mapGetters("files", { returnFileSource: "returnFileSource" })
    },
    mounted() {
        if (this.selectedEditVideo) {
            setTimeout(() => {
                if (this.selectedEditVideo.player) {
                    this.markIn = moment.utc(this.selectedEditVideo.player.currentTime() * 1000).format('HH:mm:ss')
                    this.markOut = moment.utc(this.selectedEditVideo.player.duration() * 1000).format('HH:mm:ss')
                }
            }, 500);
            this.tempSelectedEditVideo = this.selectedEditVideo
        }
    },
    beforeUnmount() {
        if (this.player) {
            this.player.dispose()
        }
        if (this.previewPlayer) {
            this.previewPlayer.dispose()
        }
    },
    watch: {
        actionState(value, oldValue) {
            this.$moshaToast({ title: this.actionState.title, description: this.actionState.message }, { type: this.actionState.result, position: 'top-center' })
        },
        previewUrl(value, oldValue) {
            if (this.previewPlayer) {
                this.previewPlayer.dispose()
            }

            setTimeout(() => {
                this.previewPlayer = videojs('preview', {
                    controls: true,
                    sources: [
                        {
                            type: "video/mp4",
                            src: this.previewUrl
                        }
                    ]
                })
            }, 100);
        },
        selectedEditVideo(value, oldValue) {
            setTimeout(() => {
                if (this.selectedEditVideo && this.selectedEditVideo.player) {
                    this.markIn = moment.utc(this.selectedEditVideo.player.currentTime() * 1000).format('HH:mm:ss')
                    this.markOut = moment.utc(this.selectedEditVideo.player.duration() * 1000).format('HH:mm:ss')
                }
            }, 600);
        }
    }
}
</script>
<style>
#player {
    margin-bottom: 10px;
}

.trim-inputs input {
    min-width: 120px;
    margin-right: 10px;
    background-color: transparent;
    color: var(--va-input-text-color);
    font-family: inherit;
    font-size: var(--va-input-font-size);
    font-stretch: var(--va-input-font-stretch);
    font-style: var(--va-input-font-style);
    font-weight: var(--va-input-font-weight);
    letter-spacing: var(--va-input-letter-spacing);
    line-height: var(--va-input-line-height);
    min-height: var(--va-input-line-height);
    outline: none;
    scrollbar-color: var(--va-input-scroll-color) transparent;
    scrollbar-width: thin;
    border: var(--va-input-border-width) solid var(--va-input-color);
    border-color: var(--va-input-bordered-color);
    padding: 5px;
    transform: translateY(2px);
}

.error .va-input-wrapper__content,
input.error {
    border: solid 1px red !important;
}

.disabled {
    opacity: 1;
}

.selected {
    border: 5px solid rgba(21, 78, 193, 0.5);
}
</style>
